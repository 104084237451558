<template>
  <div>
    <b-modal
      v-if="distance.related_distances"
      :id="'bv-modal-edit' + index"
      class="z-index"
      title="Редактировать комбо дистанций"
      hide-footer
      @show="updateSelected"
    >
      <b-form-input
        v-model="distance.name"
        placeholder="Название"
        label="Название"
        vs-placeholder="Название"
        class="w-full"
      />

      <b-form-checkbox-group
        v-for="(distance, index) in filteredDistances"
        :key="index"
        v-model="selectedDistances"
      >
        <b-form-checkbox
          :key="index"
          class="mt-1 mr-1"
          :value="distance"
          :disabled="isRestricted"
        >
          {{ distance.name }}
        </b-form-checkbox>
      </b-form-checkbox-group>

      <div class="mt-2">
        <div class="mb-1">
          Скидка за комбо
        </div>
        <b-form-input
          v-model.number="distance.combo_discount_value"
          placeholder="Скидка"
          vs-placeholder="Скидка"
          class="inline-block mb-2"
          :disabled="isRestricted"
        />
        <div class="switch">
          <div style="margin-bottom: 10px">
            Скидка в %
          </div>
          <b-form-checkbox
            v-model="distance.combo_discount_is_percent"
            class="custom-control-primary"
            name="check-reg"
            :disabled="isRestricted"
            style="margin-bottom: 10px"
            switch
          />
        </div>
      </div>
      <span
        id="save-button"
        class="d-inline-block button-container"
      >
        <b-button
          id="tooltip-target-1"
          type="submit"
          variant="primary"
          class="mr-1 combo-button"
          :disabled="selectedDistances.length < 2"
          @click="updateSport"
        >
          Сохранить
        </b-button>
      </span>
      <b-tooltip
        v-if="selectedDistances.length < 2"
        target="save-button"
      >
        Комбо должно содержать минимум 2 дистанции
      </b-tooltip>
    </b-modal>
    <b-modal
      v-else
      :id="'bv-modal-edit' + index"
      class="z-index"
      title="Редактировать дистанцию"
      ok-only
      ok-title="Сохранить"
      @ok="updateSport"
      @show="updateSelected"
    >
      <div class="con-example-prompt">
        <b-form-input
          v-model="distance.name"
          placeholder="Новая дистанция"
          label="Новая дистанция"
          vs-placeholder="Новая дистанция"
          class="w-full"
        />

        <div class="mt-3 mb-3 title-format">
          Формат
        </div>
        <div
          v-for="(format, index) in sport_formats"
          :key="'format' + index"
          class="mb-1"
        >
          <b-form-radio
            v-model="distance.format"
            name="sport_format"
            class="mr-6"
            :value="format"
          >
            {{ format }}
          </b-form-radio>
        </div>
      </div>

      <template v-if="distance.format === 'Лично'">
        <div>
          <distance-block
            :distance="distance"
            :mode="'Лично'"
            :start="start"
          />
          <div class="mt-3 mb-3 mt-3">
            Группы ({{ distance.groups.length }})
          </div>
          <group-templates @select="(group) => {distance.groups = group}" />
          <template v-for="(group, index) in distance.groups">
            <group
              :key="'group' + index"
              :groups="distance.groups"
              :group="group"
              :group-index="index"
              :is-restricted="disabledGroups.some(e => e.id === group.id)"
              @update-group="updateGroup"
              @delete-group="deleteGroup"
            />
          </template>
          <b-button
            class="mt-3"
            size="small"
            @click="addGroup"
          >
            Добавить группу
          </b-button>
        </div>
      </template>

      <template v-if="distance.format === 'Эстафета'">
        <distance-block
          :distance="distance"
          :start="start"
        />
        <div class="mt-3 mb-3 mt-3">
          Этапы ({{ distance.stages.length }})
        </div>
        <stage-block
          :stages="distance.stages"
          :restircted-stages="disabledStages"
        />
        <b-button
          class="mt-3"
          size="small"
          @click="addStage"
        >
          Добавить этап
        </b-button>

        <div class="mt-3 mb-3 mt-3">
          Группы ({{ distance.groups.length }})
        </div>
        <group-templates @select="(group) => {groups = group}" />
        <template v-for="(group, index) in distance.groups">
          <group
            :key="'group' + index"
            :groups="distance.groups"
            :group="group"
            :group-index="index"
            :distance-format="distance.format"
            :stages="distance.stages"
            @update-group="updateGroup"
            @delete-group="deleteGroup"
          />
        </template>

        <b-button
          class="mt-3"
          size="small"
          @click="addGroup"
        >
          Добавить группу
        </b-button>
      </template>

      <template>
        <div>
          <div class="mt-1 mb-1 mt-2">
            Дополнительные поля ({{ distance.additional_fields.length }})
          </div>
          <template v-for="(additionalField, index) in distance.additional_fields">
            <additionalField
              :key="'additionalField' + index"
              :distance="distance"
              :field="additionalField"
              :field-index="index"
              @update-field="updateField"
              @delete-field="deleteField"
            />
          </template>
          <b-button
            class="mt-1"
            size="small"
            @click="addField"
          >
            Добавить доп поле
          </b-button>
        </div>
      </template>

      <div class="mt-3 mb-3 mt-3">
        Скидки по возрасту
      </div>
      <div>
        <div class="discount__items">
          <div
            v-for="(item, i) in distance.discounts"
            :key="'discount_' + i"
            class="discount__item"
          >
            <div
              v-if="!disabledDiscounts.includes(item.id)"
              class="discount__item-del"
              style="margin-bottom: 10px"
              @click="distance.discounts.splice(i, 1)"
            >
              <svg
                width="10"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                />
              </svg>
            </div>
            <b-form-input
              v-model.number="item.c_from"
              type="number"
              no-wheel
              placeholder="От"
              style="margin-bottom: 10px"
            />
            <b-form-input
              v-model.number="item.c_to"
              type="number"
              no-wheel
              placeholder="До"
              style="margin-bottom: 10px"
            />
            <div class="switch">
              <div style="margin-bottom: 10px">
                Скидка в %
              </div>
              <b-form-checkbox
                v-model="item.percent"
                class="custom-control-primary"
                name="check-reg"
                style="margin-bottom: 10px"
                switch
              />
            </div>
            <b-form-input
              v-model.number="item.value"
              style="margin-bottom: 10px"
              type="number"
              no-wheel
              :placeholder=" item.percent ? '% скидки' : 'скидка(руб)'"
            />
          </div>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="
            distance.discounts.push({
              c_to: null,
              c_from: null,
              value: null,
              percent: false,
            })
          "
        >
          Добавить скидку
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormCheckbox, BButton, BModal, BFormRadio, BFormCheckboxGroup, VBPopover,
BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'
import GroupTemplates from '@/views/starts/GroupTemplates'
import DistanceBlock from './DistanceBlock.vue'
import Group from './Group.vue'
import StageBlock from './StageBlock.vue'
import AdditionalField from './AdditionalField.vue'
import Vue from 'vue'

export default {
  name: 'EditDistance',
  components: {
    StageBlock,
    Group,
    DistanceBlock,
    GroupTemplates,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    BFormRadio,
    BFormCheckboxGroup,
    AdditionalField,
    BTooltip,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: ['age_groups', 'select_kinds_sport', 'sport', 'index', 'start'],
  data() {
    return {
      distance: {},
      isRestricted: false,
      groups: [],
      slotDisable: false,
      kind_sport: '',
      activeSelectSport: false,
      new_kind_sport: '',
      sport_formats: [
        'Лично',
        'Эстафета',
      ],
      selectedDistances: [],
      disabledCombo: [],
      disabledGroups: [],
      disabledStages: [],
      disabledDiscounts: [],
    }
  },
  computed: {
    filteredDistances() {
      return this.select_kinds_sport.filter(item => !item.related_distances)
    },
  },
  created() {
    this.transformSelectedDistances()
    if (this.sport.static_price) {
      this.sport.static_price = Math.trunc(this.sport.static_price / 100)
    }
    if (this.sport.date_prices) {
      this.transformDatePrices()
    }
    if (this.sport.discounts) {
      this.transformDicounts()
    }
    if (this.sport.additional_fields) {
      this.transformFields()
    }
    if (this.sport.stages) {
      this.transformStagesFields()
    }
    if (this.start.disabledDistances) {
      const disableDistance = this.start.disabledDistances.find(e => e.uuid === this.sport.uuid)
      if (disableDistance) {
        this.isRestricted = true
        this.restrictEdit(disableDistance)
      }
    }
    if (this.sport.groups) {
      this.transformSportGroupsFields()
    }
    this.distance = JSON.parse(JSON.stringify(this.sport))
    console.log('sport', JSON.parse(JSON.stringify(this.sport)))
  },
  mounted() {
  },
  methods: {
    addStage() {
      this.distance.stages.push(
        {
          uuid: uuidv4(),
          name: null,
          sex: null,
          additional_fields: [],
          kind_of_sport_id: null,
          stage_length: null,
        },
      )
    },
    updateSelected() {
      this.distance = JSON.parse(JSON.stringify(this.sport))
      // this.transformSport()
      this.transformSelectedDistances()
    },
    addGroup() {
      if (this.distance.format === 'Лично') {
        this.distance.groups.push({
          name: null, age_from: null, age_to: null, age_reference_date: null,
        })
      } else if (this.distance.format === 'Эстафета') {
        this.distance.groups.push({
          name: null,
          age_from: null,
          age_to: null,
          age_reference_date: null,
          combination_dict: {},
        })
      }
    },
    updateSport() {
      if (this.distance.related_distances) {
        this.distance.related_distances = this.selectedDistances.map(e => e.uuid)
      } else {
        if (this.distance.format === 'Эстафета' && this.distance.groups.length > 0) {
          this.transformGroupsFields()
        }
        this.sport.name = this.distance.name
        this.sport.format = this.distance.format
        this.sport.static_price = this.distance.static_price
        this.sport.description = this.distance.description
        this.sport.date_prices = this.distance.date_prices
        this.sport.groups = this.distance.groups
        this.sport.stages = this.distance.stages
        this.sport.slots = this.distance.slots
        this.sport.infinite_slots = this.distance.infinite_slots
        this.sport.additional_fields = this.distance.additional_fields
        this.sport.discounts = this.distance.discounts
      }
      this.select_kinds_sport[this.index] = this.distance
    },
    updateGroup(group, groupIndex) {
      this.groups[groupIndex] = group
    },
    transformSport() {
      if (this.distance.static_price) {
        this.distance.static_price = Math.trunc(this.distance.static_price / 100)
      }
      if (this.distance.date_prices) {
        this.transformDatePrices()
      }
      if (this.distance.discounts) {
        this.transformDicounts()
      }
      if (this.distance.additional_fields) {
        this.transformFields()
      }
      if (this.distance.stages) {
        this.transformStagesFields()
      }
    },
    transformGroupsFields() {
      this.distance.groups.forEach(group => {
        if (Object.keys(group.combination_dict).length > 0) {
          group.combination_list = []
          for (const combinationDictKey in group.combination_dict) {
            group.combination_list.push({
              stageUUID: combinationDictKey,
              sex: group.combination_dict[combinationDictKey].length > 1 || !group.combination_dict[combinationDictKey] ? 'Any' : group.combination_dict[combinationDictKey][0],
            })
          }
        }
        delete group.combination_dict
      })
    },
    transformSportGroupsFields() {
      this.sport.groups.forEach(group => {
        if (group.combination_list) {
          group.combination_dict = {}
          group.combination_list.forEach(item => {
            if (item.sex === 'Any') {
              Vue.set(group.combination_dict, item.stageUUID, ['Male', 'Female'])
              // group.combination_dict[item.stageUUID] = ['Male', 'Female']
            } else {
              Vue.set(group.combination_dict, item.stageUUID, [item.sex])
              // group.combination_dict[item.stageUUID] = [item.sex]
            }
          })
        }
      })
    },
    transformSelectedDistances() {
      if (this.sport.related_distances) {
        this.selectedDistances = this.select_kinds_sport.filter(e => this.sport.related_distances.includes(e.uuid))
      }
    },
    transformDatePrices() {
      this.sport.date_prices.forEach(e => e.price = Math.trunc(e.price / 100))
    },
    transformDicounts() {
      this.sport.discounts.forEach(e => {
        if (!e.percent) {
          e.value = Math.trunc(e.value / 100)
        }
      })
    },
    transformFields() {
      this.sport.additional_fields.forEach(field => {
        if (field.price) {
          field.price = Math.trunc(field.price / 100)
        }
      })
    },
    transformStagesFields() {
      this.sport.stages.forEach(stage => {
        if (stage.additional_fields.length > 0) {
          stage.additional_fields.forEach(field => field.price /= 100)
        }
      })
    },
    addPrices(distance) {
      if (!distance.prices) {
        this.$set(distance, 'prices', [])
      }
      distance.prices.push({ c_to: '', c_from: '', value: '' })
    },
    deleteGroup(indexGroup) {
      this.distance.groups.splice(indexGroup, 1)
    },
    addField() {
      const uuid = uuidv4()
      this.distance.additional_fields.push({
        name: '',
        type: '',
        uuid,
      })
    },
    updateField(field, fieldIndex) {
      this.distance.additional_fields[fieldIndex] = field
    },
    deleteField(fieldIndex) {
      this.distance.additional_fields.splice(fieldIndex, 1)
    },
    restrictEdit(disableDistance) {
      if (disableDistance.groups) {
        this.disabledGroups = disableDistance.groups
      }
      if (disableDistance.stages) {
        this.disabledStages = disableDistance.stages.map(e => e.id)
      }
      if (disableDistance.discounts) {
        this.disabledDiscounts = disableDistance.discounts.map(e => e.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.button-container {
  float: right;
}

.add-fille{
  margin: 10px 0;
}
.distance{
  margin-top: 30px;
}
.title-format{
  margin: 15px 0!important;
}
.input__code{
  margin-top: 15px!important;
}
.con-example-prompt{
  input{
    margin-top: 20px!important;
  }
}
.distances__minus{
  margin-left: 5px;
  transition: .3s;
  &:hover{
    cursor: pointer;
    transform: scale(1.1);
  }
}
    .sport-block {
        border-bottom: 1px solid #e5e5e5;
    }

    .z-index {
        z-index: 52011;
    }

.price{
  &__title{
    margin-top: 20px;
  }
  &__items{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 20px -15px;
  }
  &__item{
    padding: 20px;
    border: 1px solid #00000012;
    border-radius: 20px;
    position: relative;
    width: calc(50% - 30px);
    margin: 10px 15px 0 15px;

    &-del{
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }

}

</style>
<style>
.flex{
  display: flex;

}
.justify-end{

  justify-content: end;
}
.inline-block{
  display: inline-block;
}
.age{
  display: flex;

}
.age input:last-child{
  margin-left: 30px;
}
.price__item .form-control{
  margin-top: 20px;
}
</style>
