<template>
  <div>
    <b-modal
      id="modal-1"
      centered
      ok-title="Удалить"
      cancel-title="Отменить"
      title="Удаление"
      :distance="distance"
      @ok="deleteDistance(distance)"
    >
      <div v-if="distance">
        <span>
          Вы действительно хотите удалить дистанцию "{{ distance.name }}"?
        </span>
        <span v-if="comboDistances.length > 0">
          Дистанция будет автоматически удалена из всех комбо
        </span>
      </div>
    </b-modal>

    <div
      style="width: 100%; position:relative; padding: 20px"
      class="p-6"
    >
      <div
        v-for="(sport, index) in select_kinds_sport"
        :key="'format' + index"
        class="sport-block"
      >
        <div
          :key="'form_' + index"
          class="flex items-center mt-2 mb-2"
        >
          <span>
            <b-button
              v-b-modal.modal-1
              title="Удалить"
              variant="flat-danger"
              class="btn-icon inline-block"
              size="sm"
              :disabled="disabled.includes(sport.uuid)"
              @click="deleteSport(sport, index)"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
            <strong>{{ sport.name }}</strong>
            <b-button
              variant="flat-warning"
              class="btn-icon inline-block"
              size="sm"
              @click="$bvModal.show('bv-modal-edit' + index)"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
            <span
              v-if="sport.related_distances"
              class="combo-label"
            >
              Комбо
            </span>
          </span>
        </div>
        <distance-modal-edit
          :sport="sport"
          :index="index"
          :age_groups="age_groups"
          :select_kinds_sport="select_kinds_sport"
          :start="start"
        />
      </div>
      <distance-modal-create
        :age_groups="age_groups"
        :select_kinds_sport="select_kinds_sport"
      />
      <distance-modal-combo
        :select_kinds_sport="select_kinds_sport"
      />
      <b-button
        class="mt-3"
        variant="primary"
        @click="$bvModal.show('bv-modal-create')"
      >
        Добавить дистанцию
      </b-button>

      <b-button
        class="mt-3 ml-3"
        variant="primary"
        @click="$bvModal.show('bv-modal-combo')"
      >
        Добавить комбо
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormRadio,
  BModal,
  BRow,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import StartDays from '@/views/starts/StartDays'
import DistanceModalCreate from '@/views/starts/new-version/DistanceModalCreate'
import DistanceModalEdit from '@/views/starts/new-version/DistanceModalEdit'
import DistanceModalCombo from '@/views/starts/new-version/DistanceModalCombo.vue'

export default {
  name: 'MainForm',
  components: {
    StartDays,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    flatPickr,
    BFormRadio,
    DistanceModalCreate,
    DistanceModalEdit,
    DistanceModalCombo,
  },
  props: ['start', 'select_kinds_sport', 'age_groups'],
  data() {
    return {
      distance: null,
      comboDistances: null,
      index: null,
      disabled: [],
    }
  },
  watch: {
    select_kinds_sport() {
      console.log('select_kinds_sport', JSON.parse(JSON.stringify(this.select_kinds_sport)))
    },
  },
  created() {
    if (this.start.disabledDistances) {
      this.disabled = this.start.disabledDistances.map(e => e.uuid)
    }
  },
  methods: {
    deleteSport(sport, index) {
      this.comboDistances = []
      this.start.distances.forEach(e => {
        if (e.related_distances && e.related_distances.includes(sport.uuid)) {
          this.comboDistances.push(e)
        }
      })
      this.distance = sport
      this.index = index
    },
    deleteDistance(distance) {
      if (this.comboDistances.length > 0) {
        this.start.distances.forEach((e, index) => {
          if (e.related_distances && e.related_distances.includes(distance.uuid)) {
            if (e.related_distances.length === 2) {
              this.start.distances.splice(index, 1)
            } else {
              const i = e.related_distances.indexOf(distance.uuid)
              e.related_distances.splice(i, 1)
            }
          }
        })
      }
      this.select_kinds_sport.splice(this.index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.combo-label {
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 11px;
  background-color: #ace8bb;
  color: #207936;
}
.day-button{
  color: #ffffff;
  border-radius: 5px;
}
</style>
